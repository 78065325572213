/*========================================================
        DARK LAYOUT
=========================================================*/
html
{
    font-size: 14px;

    height: 100%;

    letter-spacing: .01rem;
}
html body
{
    height: 100%;

    background-color: #f8f8f8;

    direction: ltr;
    /*
        * Blank page
        */
}
html body .content
{
    position: relative;

    min-height: -webkit-calc(100% - 4rem);
    min-height:    -moz-calc(100% - 4rem);
    min-height:         calc(100% - 4rem);
    margin-left: 260px; 
    padding: 0;

    -webkit-transition: 300ms ease all;
       -moz-transition: 300ms ease all;
         -o-transition: 300ms ease all;
            transition: 300ms ease all;

    -webkit-backface-visibility: hidden;
       -moz-backface-visibility: hidden;
            backface-visibility: hidden;
}
html body .content.app-content
{
    overflow: hidden;
}
html body .content.app-content.show-overlay .content-overlay
{
    z-index: 10;

    opacity: 1;
}
html body .content.app-content.show-overlay .content-overlay ~ .header-navbar-shadow
{
    background: -webkit-gradient(linear, left top, left bottom, color-stop(44%, rgba(44, 48, 60, .9)), color-stop(73%, rgba(44, 48, 60, .43)), to(rgba(44, 48, 60, 0)));
    background: -webkit-linear-gradient(top, rgba(44, 48, 60, .9) 44%, rgba(44, 48, 60, .43) 73%, rgba(44, 48, 60, 0));
    background:    -moz-linear-gradient(top, rgba(44, 48, 60, .9) 44%, rgba(44, 48, 60, .43) 73%, rgba(44, 48, 60, 0));
    background:      -o-linear-gradient(top, rgba(44, 48, 60, .9) 44%, rgba(44, 48, 60, .43) 73%, rgba(44, 48, 60, 0));
    background:         linear-gradient(180deg, rgba(44, 48, 60, .9) 44%, rgba(44, 48, 60, .43) 73%, rgba(44, 48, 60, 0));
}
html body .content.app-content .content-overlay
{
    position: fixed;
    z-index: -1; 
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;

    cursor: pointer;
    -webkit-transition: all .7s;
       -moz-transition: all .7s;
         -o-transition: all .7s;
            transition: all .7s;

    opacity: 0;
    background-color: rgba(0, 0, 0, .5);
}
html body .content.app-content .content-area-wrapper
{
    position: relative; 

    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    height: -webkit-calc(100% - 5rem);
    height:    -moz-calc(100% - 5rem);
    height:         calc(100% - 5rem);
    margin: -webkit-calc(5rem + 2.7rem) 2.2rem 0;
    margin:    -moz-calc(5rem + 2.7rem) 2.2rem 0;
    margin:         calc(5rem + 2.7rem) 2.2rem 0;
}
html body .content.app-content .content-area-wrapper .content-wrapper
{
    height: -webkit-calc(100vh - 13rem);
    height:    -moz-calc(100vh - 13rem);
    height:         calc(100vh - 13rem); 
    margin-top: 0;
}
html body .content .content-wrapper
{
    margin-top: 6rem; 
    padding: -webkit-calc(2.2rem - .4rem) 2.2rem 0;
    padding:    -moz-calc(2.2rem - .4rem) 2.2rem 0;
    padding:         calc(2.2rem - .4rem) 2.2rem 0;
}
html body .content .content-wrapper .content-header-title
{
    font-weight: 500;

    margin-right: 1rem; 

    color: #636363;
}
html body .content .content-wrapper .content-header-right .dropdown-toggle::after
{
    display: none;
}
html body .content .content-wrapper .content-header-right .dropdown-toggle i
{
    margin-right: 0;
}
html body .content .content-wrapper .content-header-right .btn-icon
{
    padding: .8rem;
}
html body .content .content-wrapper .content-header-right .btn-icon i
{
    font-size: 1rem;
}
html body.navbar-hidden .app-content .content-wrapper
{
    margin-top: 0; 
    padding: 2.2rem;
}
html body.navbar-hidden .app-content .content-area-wrapper
{
    margin-top: 2.5rem; 
    padding: 0;
}
html body.navbar-static .app-content .navbar-container
{
    padding-right: 1.2rem; 
    padding-left: 2.2rem;
}
html body.navbar-static .app-content .content-wrapper
{
    margin-top: 0;
    padding: 2.2rem;
    padding-top: 1rem;
}
html body.navbar-static .app-content .content-area-wrapper
{
    margin-top: 1rem;
}
html body.navbar-sticky .app-content .navbar-container
{
    padding-right: 1rem; 
    padding-left: 2.2rem;
}
html body.navbar-sticky .app-content .navbar-container .search-input .search-list.show
{
    left: 1%; 

    width: 98%;
}
html body.navbar-sticky .app-content .content-wrapper
{
    margin-top: 4.65rem; 
    padding: 2.2rem;
}
html body.navbar-static .navbar-container,
html body.navbar-sticky .navbar-container
{
    padding-right: 1rem; 
    padding-left: 2.2rem;
}
html body.navbar-static .navbar-container .search-input .search-list.show,
html body.navbar-sticky .navbar-container .search-input .search-list.show
{
    left: 1%; 

    width: 98%;
}
html body.fixed-footer .content.app-content
{
    margin-bottom: 3rem;
}
html body p
{
    line-height: 1.5rem;
}
html body.bg-full-screen-image
{
    background: url(../../app-assets/images/pages/vuesax-login-bg.jpg) no-repeat center center;
    -webkit-background-size: cover;
            background-size: cover;
}
html body.blank-page .content
{
    margin-left: 0;
}
html body.blank-page .content.app-content
{
    overflow: overlay;
    overflow-x: hidden;
}
html body.blank-page .content-wrapper
{
    margin-top: 0; 
    padding: 0 !important;
}
html body.blank-page .content-wrapper .flexbox-container
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    height: 100vh;

    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
    -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
    -ms-flex-pack: center;
            justify-content: center;
}
html body[data-col='1-column'] .content,
html body[data-col='1-column'] .footer
{
    margin-left: 0 !important;
}
html .pace .pace-progress
{
    background: #7367f0;
}

.app-content.center-layout
{
    overflow: hidden;
}

/*
* Col 3 layout for detached and general type
*/
@media (min-width: 992px)
{
    body .content-right
    {
        float: right; 

        width: -webkit-calc(100% - 260px);
        width:    -moz-calc(100% - 260px);
        width:         calc(100% - 260px);
    }
    body .content-left
    {
        float: left; 

        width: -webkit-calc(100% - 260px);
        width:    -moz-calc(100% - 260px);
        width:         calc(100% - 260px);
    }
    body .content-detached
    {
        width: 100%;
    }
    body .content-detached.content-right
    {
        float: right;

        margin-left: -260px;
    }
    body .content-detached.content-right .content-body
    {
        margin-left: -webkit-calc(260px + 2.2rem);
        margin-left:    -moz-calc(260px + 2.2rem);
        margin-left:         calc(260px + 2.2rem);
    }
    body .content-detached.content-left
    {
        float: left;

        margin-right: -260px;
    }
    body .content-detached.content-left .content-body
    {
        margin-right: -webkit-calc(260px + 2.2rem);
        margin-right:    -moz-calc(260px + 2.2rem);
        margin-right:         calc(260px + 2.2rem);
    }
    .sidebar-right.sidebar-sticky
    {
        float: right !important;

        width: 260px !important;
        margin-top: 6rem; 
        margin-left: -260px;
    }
    [data-col='content-left-sidebar'] .sticky-wrapper
    {
        float: left;
    }
}

.truncate
{
    overflow: hidden !important;

    white-space: nowrap !important; 
    text-overflow: ellipsis !important;
}

@media (max-width: 575.98px)
{
    html body .content .content-wrapper
    {
        padding: -webkit-calc(2.2rem - .4rem) -webkit-calc(2.2rem - 1rem) 0;
        padding:    -moz-calc(2.2rem - .4rem) -moz-calc(2.2rem - 1rem) 0;
        padding:         calc(2.2rem - .4rem) calc(2.2rem - 1rem) 0;
    }
}

@media (max-width: 575.98px)
{
    body.navbar-static .app-content .header-navbar .navbar-container,
    body.navbar-sticky .app-content .header-navbar .navbar-container
    {
        padding-right: .5rem; 
        padding-left: 1rem;
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
{
    html.full-screen
    {
        width: 100%;
    }
    html.full-screen .content.app-content
    {
        overflow: scroll;
        overflow-x: hidden; 

        height: 100%;
    }
}

/*=========================================================================================
    File Name: sidebar.scss
    Description: content sidebar specific scss.
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax HTML Admin Template
    Version: 1.1
    Author: PIXINVENT
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
.sidebar
{
    position: relative;

    width: 100%;
}
@media (min-width: 992px)
{
    .sidebar
    {
        vertical-align: top;
    }
}

.sidebar-fixed
{
    position: fixed;

    overflow: scroll; 

    height: 100%;
}

.sidenav-overlay
{
    position: fixed;
    z-index: 997;
    top: 0;
    right: 0;
    left: 0;

    display: none; 

    height: 120vh;

    background-color: rgba(0, 0, 0, .5);
}

.drag-target
{
    position: fixed;
    z-index: 1036; 
    top: 0;
    left: -10px;

    width: 40px;
    height: 100%;
}

@media (min-width: 992px)
{
    .sidebar-left
    {
        float: left;
    }
    .sidebar-right
    {
        float: right;
    }
}

footer.footer
{
    padding: 1rem 2.2rem;
}
footer.footer a
{
    margin: 0 .3rem;
}
footer.footer span i
{
    font-size: 1.51rem;

    position: relative;
    top: 2px; 

    margin-left: .5rem;

    color: #ea5455;
}
footer.footer .scroll-top
{
    padding: .81rem .83rem;
}

footer.navbar-shadow
{
    -webkit-box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, .15);
            box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, .15);
}

footer.navbar-border
{
    border-top: 1px solid #e4e7ed;
}

body.fixed-footer footer.footer-light
{
    background: #fff;
    -webkit-box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, .15);
            box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, .15);
}

body.fixed-footer footer.footer-dark
{
    color: #fff; 
    background: #b8c2cc;
}

.scroll-top
{
    position: fixed;
    z-index: 99; 
    right: 30px;
    bottom: 5%;

    display: none;
}

/*=========================================================================================
    File Name: navigations.scss
    Description: Common mixin for menus, contain dark and light version scss.
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax HTML Admin Template
    Version: 1.1
    Author: PIXINVENT
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
.main-menu
{
    position: absolute;
    z-index: 1031;

    display: table-cell;
    overflow: hidden; 

    height: 100%;
}
.main-menu.menu-light
{
    color: #626262;
    background: #fff;
}
.main-menu.menu-light .main-menu-header
{
    padding: 20px;
}
.main-menu.menu-light .main-menu-header .menu-search
{
    padding: .5rem 1rem;

    color: #494949; 
    background: #e6e6e6;
}
.main-menu.menu-light .main-menu-header .menu-search:focus
{
    border-color: #d9d9d9;
}
.main-menu.menu-light .navigation
{
    background: #fff;
}
.main-menu.menu-light .navigation .navigation-header
{
    line-height: 1.5;

    margin: -webkit-calc(2.2rem - .2rem) 0 .8rem 2.2rem;
    margin:    -moz-calc(2.2rem - .2rem) 0 .8rem 2.2rem;
    margin:         calc(2.2rem - .2rem) 0 .8rem 2.2rem;
    padding: 0;

    letter-spacing: .01rem; 

    color: #999;
}
.main-menu.menu-light .navigation .navigation-header span
{
    font-weight: 500;
}
.main-menu.menu-light .navigation li.has-sub ul.menu-content
{
    margin: -7px -7px 0 -7px; 
    padding: 7px;
}
.main-menu.menu-light .navigation li a
{
    line-height: 1.45;

    padding: 10px 15px 10px 15px;

    -webkit-transition: padding .35s ease 0s !important;
       -moz-transition: padding .35s ease 0s !important;
         -o-transition: padding .35s ease 0s !important;
            transition: padding .35s ease 0s !important; 

    color: #565656;
}
.main-menu.menu-light .navigation li a span.menu-sub-title
{
    color: #afafaf;
}
.main-menu.menu-light .navigation li.hover > a
{
    padding-left: 25px;

    -webkit-transition: padding .35s ease 0s !important;
       -moz-transition: padding .35s ease 0s !important;
         -o-transition: padding .35s ease 0s !important;
            transition: padding .35s ease 0s !important;
}
.main-menu.menu-light .navigation > li
{
    padding: 0 15px;
}
.main-menu.menu-light .navigation > li > a .label
{
    margin-top: 4px;
    margin-right: 5px;
}
.main-menu.menu-light .navigation > li > a i
{
    position: relative;
    top: 1px;
}
.main-menu.menu-light .navigation > li.open > a,
.main-menu.menu-light .navigation > li.sidebar-group-active > a
{
    margin-bottom: 7px; 

    transition: transform .25s ease 0s, -webkit-transform .25s ease 0s;

    color: #494949;
    border-radius: 6px;
    background: whitesmoke;
}
.main-menu.menu-light .navigation > li:not(.open) > ul
{
    display: none;
}
.main-menu.menu-light .navigation > li.active > a
{
    font-weight: 400;

    color: #fff;
    border-radius: 4px; 
    background: -webkit-linear-gradient(332deg, #7367f0, rgba(115, 103, 240, .7));
    background:    -moz-linear-gradient(332deg, #7367f0, rgba(115, 103, 240, .7));
    background:      -o-linear-gradient(332deg, #7367f0, rgba(115, 103, 240, .7));
    background:         linear-gradient(118deg, #7367f0, rgba(115, 103, 240, .7));
    -webkit-box-shadow: 0 0 10px 1px rgba(115, 103, 240, .7);
            box-shadow: 0 0 10px 1px rgba(115, 103, 240, .7);
}
.main-menu.menu-light .navigation > li .active
{
    background: #f0f0f0;
}
.main-menu.menu-light .navigation > li .active > a
{
    margin-bottom: 0; 

    color: #7367f0;
}
.main-menu.menu-light .navigation > li .active .hover > a
{
    background: whitesmoke;
}
.main-menu.menu-light .navigation > li ul
{
    margin: 0; 
    padding: 0;
}
.main-menu.menu-light .navigation > li ul li
{
    color: #626262;
    background: transparent;
}
.main-menu.menu-light .navigation > li ul li > a
{
    padding: 10px 15px 10px 20px;
}
.main-menu.menu-light .navigation > li ul .has-sub:not(.open) > ul
{
    display: none;
}
.main-menu.menu-light .navigation > li ul .open > a,
.main-menu.menu-light .navigation > li ul .sidebar-group-active > a
{
    color: #626262;
}
.main-menu.menu-light .navigation > li ul .open > ul,
.main-menu.menu-light .navigation > li ul .sidebar-group-active > ul
{
    display: block;
}
.main-menu.menu-light .navigation > li ul .open > ul .open > ul,
.main-menu.menu-light .navigation > li ul .sidebar-group-active > ul .open > ul
{
    display: block;
}
.main-menu.menu-light .navigation > li ul .hover > a,
.main-menu.menu-light .navigation > li ul:hover > a
{
    color: #6c6c6c;
}
.main-menu.menu-light .navigation > li ul .active
{
    border-radius: 4px; 
    background: -webkit-linear-gradient(332deg, #7367f0, rgba(115, 103, 240, .7));
    background:    -moz-linear-gradient(332deg, #7367f0, rgba(115, 103, 240, .7));
    background:      -o-linear-gradient(332deg, #7367f0, rgba(115, 103, 240, .7));
    background:         linear-gradient(118deg, #7367f0, rgba(115, 103, 240, .7));
    -webkit-box-shadow: 0 0 10px 1px rgba(115, 103, 240, .7);
            box-shadow: 0 0 10px 1px rgba(115, 103, 240, .7);
}
.main-menu.menu-light .navigation > li ul .active > a
{
    color: #fff;
}
.main-menu.menu-light .navigation > li > ul
{
    background: #fff;
}
.main-menu.menu-light ul.menu-popout
{
    background: #fff;
}
.main-menu.menu-light ul.menu-popout li a
{
    color: #626262;
}
.main-menu.menu-light ul.menu-popout li a span.menu-sub-title
{
    color: #afafaf;
}
.main-menu.menu-light ul.menu-popout li.hover > a,
.main-menu.menu-light ul.menu-popout li:hover > a,
.main-menu.menu-light ul.menu-popout li.open > a
{
    background: #f0f0f0;
}
.main-menu.menu-light ul.menu-popout .has-sub:not(.open) > ul
{
    display: none;
}
.main-menu.menu-light ul.menu-popout .open > a
{
    color: #626262;
}
.main-menu.menu-light ul.menu-popout .open > ul
{
    display: block;
}
.main-menu.menu-light ul.menu-popout .open > ul .open
{
    background: #e8e8e8;
}
.main-menu.menu-light ul.menu-popout .open > ul .open > ul
{
    display: block;

    background: #e8e8e8;
}
.main-menu.menu-light ul.menu-popout .hover > a,
.main-menu.menu-light ul.menu-popout:hover > a
{
    color: #6c6c6c;
    background-color: whitesmoke;
}
.main-menu.menu-light ul.menu-popout .active
{
    background: rgba(0, 0, 0, .06);
}
.main-menu.menu-light ul.menu-popout .active > a
{
    color: #7c7c7c;
    background-color: whitesmoke;
}
.main-menu.menu-light ul.menu-popout .active .hover > a,
.main-menu.menu-light ul.menu-popout .active :hover > a
{
    background-color: transparent;
}
.main-menu.menu-dark
{
    color: #dcdcdc;
    background: #10163a;
}
.main-menu.menu-dark .main-menu-header
{
    padding: 20px;
}
.main-menu.menu-dark .main-menu-header .menu-search
{
    padding: .5rem 1rem;

    color: #c3c3c3; 
    background: #050712;
}
.main-menu.menu-dark .main-menu-header .menu-search:focus
{
    border-color: black;
}
.main-menu.menu-dark .navigation
{
    background: #10163a;
}
.main-menu.menu-dark .navigation .navigation-header
{
    line-height: 1.5;

    margin: -webkit-calc(2.2rem - .2rem) 0 .8rem 2.2rem;
    margin:    -moz-calc(2.2rem - .2rem) 0 .8rem 2.2rem;
    margin:         calc(2.2rem - .2rem) 0 .8rem 2.2rem;
    padding: 0;

    letter-spacing: .01rem; 

    color: white;
}
.main-menu.menu-dark .navigation .navigation-header span
{
    font-weight: 500;
}
.main-menu.menu-dark .navigation li.has-sub ul.menu-content
{
    margin: -7px -7px 0 -7px; 
    padding: 7px;
}
.main-menu.menu-dark .navigation li a
{
    line-height: 1.45;

    padding: 10px 15px 10px 15px;

    -webkit-transition: padding .35s ease 0s !important;
       -moz-transition: padding .35s ease 0s !important;
         -o-transition: padding .35s ease 0s !important;
            transition: padding .35s ease 0s !important; 

    color: #d0d0d0;
}
.main-menu.menu-dark .navigation li a span.menu-sub-title
{
    color: white;
}
.main-menu.menu-dark .navigation li.hover > a
{
    padding-left: 25px;

    -webkit-transition: padding .35s ease 0s !important;
       -moz-transition: padding .35s ease 0s !important;
         -o-transition: padding .35s ease 0s !important;
            transition: padding .35s ease 0s !important;
}
.main-menu.menu-dark .navigation > li
{
    padding: 0 15px;
}
.main-menu.menu-dark .navigation > li > a .label
{
    margin-top: 4px;
    margin-right: 5px;
}
.main-menu.menu-dark .navigation > li > a i
{
    position: relative;
    top: 1px;
}
.main-menu.menu-dark .navigation > li.open > a,
.main-menu.menu-dark .navigation > li.sidebar-group-active > a
{
    margin-bottom: 7px; 

    transition: transform .25s ease 0s, -webkit-transform .25s ease 0s;

    color: #c3c3c3;
    border-radius: 6px;
    background: #0c102a;
}
.main-menu.menu-dark .navigation > li:not(.open) > ul
{
    display: none;
}
.main-menu.menu-dark .navigation > li.active > a
{
    font-weight: 400;

    color: #fff;
    border-radius: 4px; 
    background: -webkit-linear-gradient(332deg, #7367f0, rgba(115, 103, 240, .7));
    background:    -moz-linear-gradient(332deg, #7367f0, rgba(115, 103, 240, .7));
    background:      -o-linear-gradient(332deg, #7367f0, rgba(115, 103, 240, .7));
    background:         linear-gradient(118deg, #7367f0, rgba(115, 103, 240, .7));
    -webkit-box-shadow: 0 0 10px 1px rgba(115, 103, 240, .7);
            box-shadow: 0 0 10px 1px rgba(115, 103, 240, .7);
}
.main-menu.menu-dark .navigation > li .active
{
    background: #090d22;
}
.main-menu.menu-dark .navigation > li .active > a
{
    margin-bottom: 0; 

    color: #7367f0;
}
.main-menu.menu-dark .navigation > li .active .hover > a
{
    background: #0c102a;
}
.main-menu.menu-dark .navigation > li ul
{
    margin: 0; 
    padding: 0;
}
.main-menu.menu-dark .navigation > li ul li
{
    color: #dcdcdc;
    background: transparent;
}
.main-menu.menu-dark .navigation > li ul li > a
{
    padding: 10px 15px 10px 20px;
}
.main-menu.menu-dark .navigation > li ul .has-sub:not(.open) > ul
{
    display: none;
}
.main-menu.menu-dark .navigation > li ul .open > a,
.main-menu.menu-dark .navigation > li ul .sidebar-group-active > a
{
    color: #dcdcdc;
}
.main-menu.menu-dark .navigation > li ul .open > ul,
.main-menu.menu-dark .navigation > li ul .sidebar-group-active > ul
{
    display: block;
}
.main-menu.menu-dark .navigation > li ul .open > ul .open > ul,
.main-menu.menu-dark .navigation > li ul .sidebar-group-active > ul .open > ul
{
    display: block;
}
.main-menu.menu-dark .navigation > li ul .hover > a,
.main-menu.menu-dark .navigation > li ul:hover > a
{
    color: #e6e6e6;
}
.main-menu.menu-dark .navigation > li ul .active
{
    border-radius: 4px; 
    background: -webkit-linear-gradient(332deg, #7367f0, rgba(115, 103, 240, .7));
    background:    -moz-linear-gradient(332deg, #7367f0, rgba(115, 103, 240, .7));
    background:      -o-linear-gradient(332deg, #7367f0, rgba(115, 103, 240, .7));
    background:         linear-gradient(118deg, #7367f0, rgba(115, 103, 240, .7));
    -webkit-box-shadow: 0 0 10px 1px rgba(115, 103, 240, .7);
            box-shadow: 0 0 10px 1px rgba(115, 103, 240, .7);
}
.main-menu.menu-dark .navigation > li ul .active > a
{
    color: #fff;
}
.main-menu.menu-dark .navigation > li > ul
{
    background: #10163a;
}
.main-menu.menu-dark ul.menu-popout
{
    background: #10163a;
}
.main-menu.menu-dark ul.menu-popout li a
{
    color: #dcdcdc;
}
.main-menu.menu-dark ul.menu-popout li a span.menu-sub-title
{
    color: white;
}
.main-menu.menu-dark ul.menu-popout li.hover > a,
.main-menu.menu-dark ul.menu-popout li:hover > a,
.main-menu.menu-dark ul.menu-popout li.open > a
{
    background: #090d22;
}
.main-menu.menu-dark ul.menu-popout .has-sub:not(.open) > ul
{
    display: none;
}
.main-menu.menu-dark ul.menu-popout .open > a
{
    color: #dcdcdc;
}
.main-menu.menu-dark ul.menu-popout .open > ul
{
    display: block;
}
.main-menu.menu-dark ul.menu-popout .open > ul .open
{
    background: #060816;
}
.main-menu.menu-dark ul.menu-popout .open > ul .open > ul
{
    display: block;

    background: #060816;
}
.main-menu.menu-dark ul.menu-popout .hover > a,
.main-menu.menu-dark ul.menu-popout:hover > a
{
    color: #e6e6e6;
    background-color: #0c102a;
}
.main-menu.menu-dark ul.menu-popout .active
{
    background: rgba(0, 0, 0, .06);
}
.main-menu.menu-dark ul.menu-popout .active > a
{
    color: #f6f6f6;
    background-color: #0c102a;
}
.main-menu.menu-dark ul.menu-popout .active .hover > a,
.main-menu.menu-dark ul.menu-popout .active :hover > a
{
    background-color: transparent;
}
.main-menu.menu-fixed
{
    position: fixed;
    /* fallback if needed */
}
.main-menu.menu-fixed .main-menu-footer
{
    position: fixed;
}
.main-menu.menu-static
{
    height: auto;
    padding-bottom: -webkit-calc(100% - 35rem);
    padding-bottom:    -moz-calc(100% - 35rem);
    padding-bottom:         calc(100% - 35rem);
}
.main-menu.menu-static .main-menu-content
{
    height: unset !important;
}
.main-menu.menu-shadow
{
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .05);
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, .05);
}
.main-menu.menu-border
{
    border-right: 1px solid #e4e7ed;
}
.main-menu .shadow-bottom
{
    position: absolute;
    z-index: 2;

    display: none;

    width: 100%;
    height: 60px;
    margin-top: -1.3rem;

    pointer-events: none;

    background: -webkit-gradient(linear, left top, left bottom, color-stop(41%, #fff), color-stop(95%, rgba(255, 255, 255, .11)), to(rgba(255, 255, 255, 0)));
    background: -webkit-linear-gradient(#fff 41%, rgba(255, 255, 255, .11) 95%, rgba(255, 255, 255, 0));
    background:    -moz-linear-gradient(#fff 41%, rgba(255, 255, 255, .11) 95%, rgba(255, 255, 255, 0));
    background:      -o-linear-gradient(#fff 41%, rgba(255, 255, 255, .11) 95%, rgba(255, 255, 255, 0));
    background:         linear-gradient(#fff 41%, rgba(255, 255, 255, .11) 95%, rgba(255, 255, 255, 0)); 

    -webkit-filter: blur(5px);
            filter: blur(5px);
}
.main-menu.menu-native-scroll .main-menu-content
{
    overflow-y: scroll;
}
.main-menu .navbar-header
{
    position: relative;

    width: 260px;
    height: 100%;
    height: 5rem;
    padding: .35rem 1rem .3rem 1.64rem;

    -webkit-transition: 300ms ease all;
       -moz-transition: 300ms ease all;
         -o-transition: 300ms ease all;
            transition: 300ms ease all;
}
.main-menu .navbar-header .navbar-brand
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    margin-top: 1.35rem; 

    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
    -ms-flex-align: center;
            align-items: center;
}
.main-menu .navbar-header .navbar-brand .brand-logo
{
    width: 35px; 
    height: 24px;

    background: url('../../app-assets/images/logo/vuesax-logo.png') no-repeat;
    background-position: -65px -54px;
}
.main-menu .navbar-header .navbar-brand .brand-text
{
    font-size: 1.57rem;
    font-weight: 600;

    padding-left: 1rem;

    -webkit-animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadein;
       -moz-animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadein;
         -o-animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadein;
            animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadein; 
    letter-spacing: .01rem;

    color: #7367f0;
}
.main-menu .navbar-header .modern-nav-toggle
{
    -webkit-animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadein;
       -moz-animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadein;
         -o-animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadein;
            animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadein;
}
.main-menu .main-menu-content
{
    position: relative; 

    height: -webkit-calc(100% - 6rem) !important;
    height:    -moz-calc(100% - 6rem) !important;
    height:         calc(100% - 6rem) !important;
}
.main-menu ul
{
    margin: 0;
    padding: 0; 

    list-style: none;
}
.main-menu ul.navigation-main
{
    overflow-x: hidden;
}
.main-menu ul.navigation-main > li:first-child
{
    margin-top: .5rem;
}
.main-menu a
{
    outline: none;
}
.main-menu a:hover,
.main-menu a:focus
{
    text-decoration: none;
}

.navigation
{
    font-family: 'Montserrat', Helvetica, Arial, serif;
    font-size: 1.1rem;
    font-weight: 400;

    overflow-y: hidden;

    padding-bottom: 20px;
}
.navigation .navigation-header
{
    font-family: 'Montserrat', Helvetica, Arial, serif;
    font-size: .9rem;
    font-weight: 500;
    line-height: 1.2;

    padding: 12px 22px;

    text-transform: uppercase;
}
.navigation li
{
    position: relative;

    white-space: nowrap;
}
.navigation li a
{
    display: block;
    overflow: hidden; 

    text-overflow: ellipsis;
}
.navigation li a i
{
    font-size: .8rem;

    margin-right: 1.38rem;
}
.navigation li.disabled a
{
    cursor: not-allowed;
}

.menu-popout li.disabled a
{
    cursor: not-allowed;
}

.dropdown-notification .nav-link-label
{
    position: relative;
}

.dropdown-notification .notification-text
{
    font-size: smaller;

    margin-bottom: .5rem;

    color: #626262;
}

.dropdown-notification .notification-title
{
    color: rgba(255, 255, 255, .75);
}

.dropdown-notification .notification-tag
{
    position: relative;
    top: -4px;
}

.dropdown-notification .dropdown-menu.dropdown-menu-right
{
    right: -2px;

    padding: 0;
}
.dropdown-notification .dropdown-menu.dropdown-menu-right::before
{
    border-color: #7367f0; 
    background: #7367f0;
}

.dropdown-notification .dropdown-menu-header
{
    text-align: center; 

    color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: #7367f0;
}
.dropdown-notification .dropdown-menu-header .dropdown-header h3
{
    margin-bottom: .25rem;
}

.main-menu-header .user-content
{
    padding: 20px;
}
.main-menu-header .user-content .media
{
    overflow: inherit;
}
.main-menu-header .user-content .media-body
{
    width: 150px;

    -webkit-transition: all .3s ease .15s;
       -moz-transition: all .3s ease .15s;
         -o-transition: all .3s ease .15s;
            transition: all .3s ease .15s; 
    vertical-align: bottom;
    white-space: nowrap;

    opacity: 1;
}

.main-menu-footer
{
    position: relative;
    z-index: 1000;
    bottom: 0;

    display: block;
    overflow: hidden;

    color: white;
    background-color: #9aa9b7;
}
.main-menu-footer.footer-open
{
    max-height: 500px;

    -webkit-transition: max-height .2s ease-in-out;
       -moz-transition: max-height .2s ease-in-out;
         -o-transition: max-height .2s ease-in-out;
            transition: max-height .2s ease-in-out;
}
.main-menu-footer a
{
    color: white;
}
.main-menu-footer a:hover,
.main-menu-footer a:focus
{
    color: #fff;
}
.main-menu-footer .header
{
    height: 19px;

    border-bottom: 1px solid #a9b5c1;
}
.main-menu-footer .toggle
{
    transition: -webkit-transform .2s ease-in-out; 
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg);
}
.main-menu-footer .content
{
    padding: 0;
}
.main-menu-footer .content .actions > a
{
    display: block;
    float: left;

    width: 33.33333%;
    padding: 1rem 0;

    text-align: center;

    color: white;
    border-top: 1px solid #a9b5c1;
    border-left: 1px solid #a9b5c1;
}
.main-menu-footer .content .actions > a > span
{
    font-size: 1.35rem;
}
.main-menu-footer .content .actions > a:hover,
.main-menu-footer .content .actions > a:focus
{
    color: #fff;
}

body.vertical-layout.vertical-menu.menu-expanded .main-menu-footer .content
{
    margin-left: 0;
}

body.menu-collapsed .menu-static
{
    padding-bottom: -webkit-calc(100% - 14rem);
    padding-bottom:    -moz-calc(100% - 14rem);
    padding-bottom:         calc(100% - 14rem);
}

@media (max-width: 767.98px)
{
    .menu-hide .main-menu,
    .menu-open .main-menu
    {
        -webkit-transition: top .35s, height .35s, -webkit-transform .25s;
           -moz-transition: transform .25s, top .35s, height .35s, -moz-transform .25s;
             -o-transition: top .35s, height .35s, -o-transform .25s;
                transition: top .35s, height .35s, -webkit-transform .25s;
                transition: transform .25s, top .35s, height .35s;
                transition: transform .25s, top .35s, height .35s, -webkit-transform .25s, -moz-transform .25s, -o-transform .25s;
    }
    .main-menu
    {
        -webkit-transform: translate3d(-240px, 0, 0);
           -moz-transform: translate3d(-240px, 0, 0);
                transform: translate3d(-240px, 0, 0);

        -webkit-backface-visibility: hidden;
           -moz-backface-visibility: hidden;
                backface-visibility: hidden;
        -webkit-perspective: 1000;
           -moz-perspective: 1000;
                perspective: 1000;
    }
    .menu-open .main-menu
    {
        -webkit-transform: translate3d(0, 0, 0);
           -moz-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

@media (min-width: 768px)
{
    .drag-target
    {
        z-index: 0;
    }
}

body.fixed-footer .main-menu.menu-fixed
{
    height: 100%;
}

@media (max-width: 768px)
{
    body .main-menu.menu-static
    {
        padding-bottom: 100%;
    }
}

.display-inline
{
    display: inline !important;
}

.display-block
{
    display: block !important;
}

.display-inline-block
{
    display: inline-block !important;
}

.display-hidden
{
    display: none !important;
}

.display-table-cell
{
    display: table-cell !important;
}

.position-top-0
{
    top: 0;
}

.position-right-0
{
    right: 0;
}

.position-bottom-0
{
    bottom: 0;
}

.position-left-0
{
    left: 0;
}

.zindex-1
{
    z-index: 1 !important;
}

.zindex-2
{
    z-index: 2 !important;
}

.zindex-3
{
    z-index: 3 !important;
}

.zindex-4
{
    z-index: 4 !important;
}

.zindex-0
{
    z-index: 0 !important;
}

.zindex-minus-1
{
    z-index: -1 !important;
}

.zindex-minus-2
{
    z-index: -2 !important;
}

.zindex-minus-3
{
    z-index: -3 !important;
}

.zindex-minus-4
{
    z-index: -4 !important;
}

.no-edge-top
{
    top: 0 !important;
}

.no-edge-bottom
{
    bottom: 0 !important;
}

.no-edge-left
{
    left: 0 !important;
}

.no-edge-right
{
    right: 0 !important;
}

.cursor-pointer
{
    cursor: pointer;
}

.cursor-move
{
    cursor: move;
}

.cursor-default
{
    cursor: default;
}

.cursor-progress
{
    cursor: progress;
}

.cursor-not-allowed
{
    cursor: not-allowed;
}

.overflow-hidden
{
    overflow: hidden;
}

.overflow-visible
{
    overflow: visible;
}

.overflow-auto
{
    overflow: auto;
}

.overflow-scroll
{
    overflow: scroll;
}

.overflow-x-scroll
{
    overflow: scroll;
}

.overflow-y-scroll
{
    overflow: scroll;
}

.bullets-inside
{
    list-style: inside;
}

.list-style-circle
{
    list-style: circle;
}

.list-style-square
{
    list-style: square;
}

.list-style-icons
{
    margin-left: 0;
    padding-left: 10px;

    list-style: none;
}
.list-style-icons > li i
{
    float: left;

    width: 1em;
    margin: 0 6px 0 0;
}

.border
{
    border: 1px solid;
}

.border-top
{
    border-top: 1px solid;
}

.border-bottom
{
    border-bottom: 1px solid;
}

.border-left
{
    border-left: 1px solid;
}

.border-right
{
    border-right: 1px solid;
}

.border-2
{
    border-width: 2px !important;
}

.border-top-2
{
    border-top-width: 2px !important;
}

.border-bottom-2
{
    border-bottom-width: 2px !important;
}

.border-left-2
{
    border-left-width: 2px !important;
}

.border-right-2
{
    border-right-width: 2px !important;
}

.border-3
{
    border-width: 3px !important;
}

.border-top-3
{
    border-top-width: 3px !important;
}

.border-bottom-3
{
    border-bottom-width: 3px !important;
}

.border-left-3
{
    border-left-width: 3px !important;
}

.border-right-3
{
    border-right-width: 3px !important;
}

.no-border-top-radius
{
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.no-border-bottom-radius
{
    border-bottom-right-radius: 0 !important; 
    border-bottom-left-radius: 0 !important;
}

.no-border-top-left-radius
{
    border-top-left-radius: 0 !important;
}

.no-border-top-right-radius
{
    border-top-right-radius: 0 !important;
}

.no-border-bottom-left-radius
{
    border-bottom-left-radius: 0 !important;
}

.no-border-bottom-right-radius
{
    border-bottom-right-radius: 0 !important;
}

.box-shadow-0
{
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
}

.box-shadow-1
{
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
}

.box-shadow-2
{
    -webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);
            box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);
}

.box-shadow-3
{
    -webkit-box-shadow: 0 12px 15px 0 rgba(0, 0, 0, .24), 0 17px 50px 0 rgba(0, 0, 0, .19);
            box-shadow: 0 12px 15px 0 rgba(0, 0, 0, .24), 0 17px 50px 0 rgba(0, 0, 0, .19);
}

.box-shadow-4
{
    -webkit-box-shadow: 0 16px 28px 0 rgba(0, 0, 0, .22), 0 25px 55px 0 rgba(0, 0, 0, .21);
            box-shadow: 0 16px 28px 0 rgba(0, 0, 0, .22), 0 25px 55px 0 rgba(0, 0, 0, .21);
}

.box-shadow-5
{
    -webkit-box-shadow: 0 27px 24px 0 rgba(0, 0, 0, .2), 0 40px 77px 0 rgba(0, 0, 0, .22);
            box-shadow: 0 27px 24px 0 rgba(0, 0, 0, .2), 0 40px 77px 0 rgba(0, 0, 0, .22);
}

.box-shadow-6
{
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12), 0 2px 4px 0 rgba(0, 0, 0, .08);
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12), 0 2px 4px 0 rgba(0, 0, 0, .08);
}

.fit
{
    max-width: 100% !important;
}

.half-width
{
    width: 50% !important;
}

.full-width
{
    width: 100% !important;
}

.full-height
{
    height: 100% !important;
}

.width-50
{
    width: 50px !important;
}

.width-100
{
    width: 100px !important;
}

.width-150
{
    width: 150px !important;
}

.width-200
{
    width: 200px !important;
}

.width-250
{
    width: 250px !important;
}

.width-300
{
    width: 300px !important;
}

.width-350
{
    width: 350px !important;
}

.width-400
{
    width: 400px !important;
}

.width-450
{
    width: 450px !important;
}

.width-500
{
    width: 500px !important;
}

.width-550
{
    width: 550px !important;
}

.width-600
{
    width: 600px !important;
}

.width-650
{
    width: 650px !important;
}

.width-700
{
    width: 700px !important;
}

.width-750
{
    width: 750px !important;
}

.width-800
{
    width: 800px !important;
}

.width-5-per
{
    width: 5% !important;
}

.width-10-per
{
    width: 10% !important;
}

.width-15-per
{
    width: 15% !important;
}

.width-20-per
{
    width: 20% !important;
}

.width-25-per
{
    width: 25% !important;
}

.width-30-per
{
    width: 30% !important;
}

.width-35-per
{
    width: 35% !important;
}

.width-40-per
{
    width: 40% !important;
}

.width-45-per
{
    width: 45% !important;
}

.width-50-per
{
    width: 50% !important;
}

.width-55-per
{
    width: 55% !important;
}

.width-60-per
{
    width: 60% !important;
}

.width-65-per
{
    width: 65% !important;
}

.width-70-per
{
    width: 70% !important;
}

.width-75-per
{
    width: 75% !important;
}

.width-80-per
{
    width: 80% !important;
}

.width-90-per
{
    width: 90% !important;
}

.width-95-per
{
    width: 95% !important;
}

.height-50
{
    height: 50px !important;
}

.height-75
{
    height: 75px !important;
}

.height-100
{
    height: 100px !important;
}

.height-150
{
    height: 150px !important;
}

.height-200
{
    height: 200px !important;
}

.height-250
{
    height: 250px !important;
}

.height-300
{
    height: 300px !important;
}

.height-350
{
    height: 350px !important;
}

.height-400
{
    height: 400px !important;
}

.height-450
{
    height: 450px !important;
}

.height-500
{
    height: 500px !important;
}

.height-550
{
    height: 550px !important;
}

.height-600
{
    height: 600px !important;
}

.height-650
{
    height: 650px !important;
}

.height-700
{
    height: 700px !important;
}

.height-750
{
    height: 750px !important;
}

.height-800
{
    height: 800px !important;
}

.height-5-per
{
    height: 5% !important;
}

.height-10-per
{
    height: 10% !important;
}

.height-15-per
{
    height: 15% !important;
}

.height-20-per
{
    height: 20% !important;
}

.height-25-per
{
    height: 25% !important;
}

.height-30-per
{
    height: 30% !important;
}

.height-35-per
{
    height: 35% !important;
}

.height-40-per
{
    height: 40% !important;
}

.height-45-per
{
    height: 45% !important;
}

.height-50-per
{
    height: 50% !important;
}

.height-55-per
{
    height: 55% !important;
}

.height-60-per
{
    height: 60% !important;
}

.height-65-per
{
    height: 65% !important;
}

.height-70-per
{
    height: 70% !important;
}

.height-75-per
{
    height: 75% !important;
}

.height-80-per
{
    height: 80% !important;
}

.full-height-vh-with-nav
{
    height: -webkit-calc(100vh - 5rem - 4rem);
    height:    -moz-calc(100vh - 5rem - 4rem);
    height:         calc(100vh - 5rem - 4rem);
}

.full-height-vh
{
    height: 100vh;
}

.line-height-1
{
    line-height: 1 !important;
}

.line-height-2
{
    line-height: 2 !important;
}

.rotate-45
{
    -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
         -o-transform: rotate(45deg);
            transform: rotate(45deg);
}

.rotate-45-inverse
{
    -webkit-transform: rotate(-45deg);
       -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
         -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.rotate-90
{
    -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
         -o-transform: rotate(45deg);
            transform: rotate(45deg);
}

.rotate-90-inverse
{
    -webkit-transform: rotate(-45deg);
       -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
         -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.rotate-180
{
    -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
         -o-transform: rotate(45deg);
            transform: rotate(45deg);
}

.rotate-180-inverse
{
    -webkit-transform: rotate(-45deg);
       -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
         -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.pull-up
{
    -webkit-transition: all .25s ease;
       -moz-transition: all .25s ease;
         -o-transition: all .25s ease;
            transition: all .25s ease;
}
.pull-up:hover
{
    z-index: 30; 

    -webkit-transform: translateY(-4px) scale(1.02);
       -moz-transform: translateY(-4px) scale(1.02);
        -ms-transform: translateY(-4px) scale(1.02);
         -o-transform: translateY(-4px) scale(1.02);
            transform: translateY(-4px) scale(1.02);

    -webkit-box-shadow: 0 14px 24px rgba(62, 57, 107, .2);
            box-shadow: 0 14px 24px rgba(62, 57, 107, .2);
}

.spinner
{
    display: inline-block;

    -webkit-animation: spin 1s linear infinite;
       -moz-animation: spin 1s linear infinite;
         -o-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
}

@-webkit-keyframes spin
{
    0%
    {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100%
    {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@-moz-keyframes spin
{
    0%
    {
        -moz-transform: rotate(0deg);
             transform: rotate(0deg);
    }
    100%
    {
        -moz-transform: rotate(360deg);
             transform: rotate(360deg);
    }
}

@-o-keyframes spin
{
    0%
    {
        -o-transform: rotate(0deg);
           transform: rotate(0deg);
    }
    100%
    {
        -o-transform: rotate(360deg);
           transform: rotate(360deg);
    }
}

@keyframes spin
{
    0%
    {
        -webkit-transform: rotate(0deg);
           -moz-transform: rotate(0deg);
             -o-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100%
    {
        -webkit-transform: rotate(360deg);
           -moz-transform: rotate(360deg);
             -o-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.spinner-reverse
{
    display: inline-block;

    -webkit-animation: spin-reverse 1s linear infinite;
       -moz-animation: spin-reverse 1s linear infinite;
         -o-animation: spin-reverse 1s linear infinite;
            animation: spin-reverse 1s linear infinite;
}

@-webkit-keyframes spin-reverse
{
    0%
    {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100%
    {
        -webkit-transform: rotate(-360deg);
                transform: rotate(-360deg);
    }
}

@-moz-keyframes spin-reverse
{
    0%
    {
        -moz-transform: rotate(0deg);
             transform: rotate(0deg);
    }
    100%
    {
        -moz-transform: rotate(-360deg);
             transform: rotate(-360deg);
    }
}

@-o-keyframes spin-reverse
{
    0%
    {
        -o-transform: rotate(0deg);
           transform: rotate(0deg);
    }
    100%
    {
        -o-transform: rotate(-360deg);
           transform: rotate(-360deg);
    }
}

@keyframes spin-reverse
{
    0%
    {
        -webkit-transform: rotate(0deg);
           -moz-transform: rotate(0deg);
             -o-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100%
    {
        -webkit-transform: rotate(-360deg);
           -moz-transform: rotate(-360deg);
             -o-transform: rotate(-360deg);
                transform: rotate(-360deg);
    }
}

.bg-cover
{
    -webkit-background-size: cover !important;
            background-size: cover !important;
}

.background-repeat
{
    background-repeat: repeat !important;
}

.background-no-repeat
{
    background-repeat: no-repeat !important;
}

.img-xl
{
    width: 64px !important;
    height: 64px !important;
}

.img-lg
{
    width: 44px !important;
    height: 44px !important;
}

.img-sm
{
    width: 36px !important;
    height: 36px !important;
}

.img-xs
{
    width: 32px !important;
    height: 32px !important;
}

.bullet
{
    display: inline-block; 

    width: 1rem;
    height: 1rem;

    border-radius: 50%;
}
.bullet.bullet-xs
{
    width: .5rem;
    height: .5rem;
}
.bullet.bullet-sm
{
    width: .714rem;
    height: .714rem;
}
.bullet.bullet-lg
{
    width: 1.25rem;
    height: 1.25rem;
}

.avatar
{
    font-size: .75rem;

    position: relative;

    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display:    -moz-inline-box;
    display: -ms-inline-flexbox;
    display:         inline-flex;

    margin: 5px; 

    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;

    color: #fff;
    border-radius: 50%;
    background-color: #c3c3c3;
}
.avatar .avatar-content
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    width: 32px;
    height: 32px;

    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
    -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
    -ms-flex-align: center;
            align-items: center;
}
.avatar .avatar-content .avatar-icon
{
    font-size: 1.2rem;
}
.avatar [class*='avatar-status-']
{
    position: absolute;
    right: 0;
    bottom: 0;

    width: 11px;
    height: 11px;

    border: 1px solid #fff; 
    border-radius: 50%;
}
.avatar [class*='avatar-status-'].avatar-status-lg
{
    width: 17px;
    height: 17px;

    border-width: 2px;
}
.avatar .avatar-status-online
{
    background-color: #28c76f;
}
.avatar .avatar-status-busy
{
    background-color: #ea5455;
}
.avatar .avatar-status-away
{
    background-color: #ff9f43;
}
.avatar .avatar-status-offline
{
    background-color: #b8c2cc;
}
.avatar img
{
    border-radius: 50%;
}
.avatar.avatar-xl
{
    font-size: 1.5rem;
}
.avatar.avatar-xl img
{
    width: 70px;
    height: 70px;
}
.avatar.avatar-xl .avatar-content
{
    width: 70px; 
    height: 70px;
}
.avatar.avatar-xl .avatar-content .avatar-icon
{
    font-size: 3rem;
}
.avatar.avatar-lg
{
    font-size: 1.2rem;
}
.avatar.avatar-lg img
{
    width: 50px;
    height: 50px;
}
.avatar.avatar-lg .avatar-content
{
    width: 50px;
    height: 50px;
}
.avatar.avatar-lg .avatar-content .avatar-icon
{
    font-size: 2rem;
}
.avatar.avatar-sm .avatar-content
{
    width: 24px;
    height: 24px;
}
.avatar.avatar-sm .avatar-content .avatar-icon
{
    font-size: 1rem;
}
.avatar.avatar-sm img
{
    width: 24px;
    height: 24px;
}

/*=========================================================================================
	File Name: search.scss
	Description: Search  functionality.
	----------------------------------------------------------------------------------------
	Item Name: Vuesax HTML Admin Template
	Version: 1.1
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
.navbar-container a.nav-link-search
{
    float: left;
}

.navbar-container .search-input
{
    float: left;

    width: 0;
}
.navbar-container .search-input input
{
    line-height: 16px;

    width: 0;
    padding: 1.6rem 3.6rem; 

    -webkit-transition: all .2s ease-out;
       -moz-transition: all .2s ease-out;
         -o-transition: all .2s ease-out;
            transition: all .2s ease-out;

    border: none;
    background: none;
}
.navbar-container .search-input.open
{
    position: absolute;
    z-index: 1;
    right: 0;
    left: 0;

    width: 100%;

    border-radius: .5rem; 
    background: #fff;
}
.navbar-container .search-input.open .search-input-close,
.navbar-container .search-input.open .search-input-icon
{
    display: block;
}
.navbar-container .search-input.open input
{
    width: 100%;

    -webkit-transition: all .3s ease-out;
       -moz-transition: all .3s ease-out;
         -o-transition: all .3s ease-out;
            transition: all .3s ease-out; 

    outline: none;
    background: none;
}
.navbar-container .search-input .search-list
{
    position: absolute;
    top: 100%;
    left: 0;

    display: none; 

    width: 100%;
    margin-top: .5rem;
    padding-left: 0;

    border-radius: .5rem;
    background: #fff;
}
.navbar-container .search-input .search-list.show
{
    display: block;
}
.navbar-container .search-input .search-list li a
{
    padding: .9rem 1rem;

    color: #626262;
}
.navbar-container .search-input .search-list li a span[class*='feather icon-']
{
    font-size: 1.25rem;
}
.navbar-container .search-input .search-list li:first-child
{
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
}
.navbar-container .search-input .search-list li:last-child
{
    border-bottom-right-radius: .5rem; 
    border-bottom-left-radius: .5rem;
}
.navbar-container .search-input .search-list li:hover,
.navbar-container .search-input .search-list li.current_item
{
    background-color: #f1f1f1;
}
.navbar-container .search-input .search-input-icon
{
    position: absolute;
    z-index: 2;
    top: 37%;
    left: 1.5rem;

    display: none;

    cursor: pointer;
}
.navbar-container .search-input .search-input-icon i
{
    font-size: 1.25rem;
}
.navbar-container .search-input .search-input-close
{
    position: absolute;
    z-index: 1;
    top: 32%;
    right: 2rem;

    display: none;

    cursor: pointer;
}
.navbar-container .search-input .search-input-close i
{
    font-size: 1.75rem;
}

.navbar-container .bookmark-input
{
    position: absolute;
    top: 102%;

    display: none; 

    width: 25%;
}
.navbar-container .bookmark-input.show
{
    display: block;
}
.navbar-container .bookmark-input .bookmark-input-icon
{
    position: absolute;
    z-index: 1;
    top: .85rem;
    left: 1rem;
}
.navbar-container .bookmark-input input
{
    width: 100%;
    padding: .7rem .7rem .7rem 2.5rem;

    border: 1px solid rgba(0, 0, 0, .2); 
    background: #fff;
}
.navbar-container .bookmark-input input:focus
{
    border: 1px solid #7367f0;
}
.navbar-container .bookmark-input .search-list
{
    -webkit-box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
            box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
}

.navbar-container .bookmark-icon
{
    cursor: pointer;
}

.navbar-container .dropdown.bookmark-dropdown
{
    padding: 1.4rem .5rem 1.35rem;
}
.navbar-container .dropdown.bookmark-dropdown .dropdown-toggle:after
{
    display: none;
}

/* .navbar-light, .navbar-semi-dark{
	.search-input{
		.input{
			color: $gray-600;
		}
		&.open{
			.input{
				color: $gray-600;
				// border-bottom: 1px solid $gray-600;

			}
		}
	}
} */
.navbar-dark .search-input .input,
.navbar-semi-light .search-input .input
{
    color: #f5f7fa;
    border-radius: .5rem;
}

.navbar-dark .search-input.open .input,
.navbar-semi-light .search-input.open .input
{
    color: #f5f7fa;
    border: 1px solid #f5f7fa;
}

@media (max-width: 767.98px)
{
    #navbar-mobile .search-input.open .input
    {
        color: #b8c2cc;
    }
}

body.navbar-static .navbar-container .search-input.open
{
    border-radius: 0;
}

.apexcharts-canvas .apexcharts-tooltip
{
    color: #626262 !important;
}
.apexcharts-canvas .apexcharts-tooltip.dark
{
    color: #fff !important;
}

.apexcharts-canvas .apexcharts-toolbar .apexcharts-menu .apexcharts-menu-item
{
    color: #626262;
}

.apexcharts-xaxistooltip
{
    color: #626262 !important;
}

#client-retention-chart .apexcharts-canvas .apexcharts-legend
{
    left: -14px !important;
}

.btn-social,
.btn-social-icon
{
    position: relative;

    overflow: hidden;

    padding-left: 4.95rem;

    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.btn-social > :first-child,
.btn-social-icon > :first-child
{
    font-size: 1rem;
    line-height: 2.7rem;

    position: absolute;
    top: 2px;
    bottom: 0;
    left: 0;

    width: 2.95rem;

    text-align: center;

    border-right: 1px solid rgba(0, 0, 0, .2);
}

.btn-social-icon
{
    width: 2.95rem;
    height: 2.95rem;
    padding: 0;
}
.btn-social-icon > :first-child
{
    width: 100% !important; 

    text-align: center;

    border: none;
}

.btn-adn
{
    color: #fff;
    border-color: #fff; 
    background-color: #d87a68;
    background-color: #d87a68;
}
.btn-adn:hover
{
    color: #fff;
    border-color: #d87a68; 
    background-color: #d87a68;
}
.btn-adn:focus,
.btn-adn.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}
.btn-adn.disabled,
.btn-adn:disabled
{
    color: #fff;
    border-color: #fff; 
    background-color: #d87a68;
}
.btn-adn:not(:disabled):not(.disabled):active,
.btn-adn:not(:disabled):not(.disabled).active,
.show > .btn-adn.dropdown-toggle
{
    color: #fff;
    border-color: #dfdfdf; 
    background-color: #ce563f;
}
.btn-adn:not(:disabled):not(.disabled):active:focus,
.btn-adn:not(:disabled):not(.disabled).active:focus,
.show > .btn-adn.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}

.btn-bitbucket
{
    color: #fff;
    border-color: #fff; 
    background-color: #205081;
    background-color: #205081;
}
.btn-bitbucket:hover
{
    color: #fff;
    border-color: #205081; 
    background-color: #205081;
}
.btn-bitbucket:focus,
.btn-bitbucket.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}
.btn-bitbucket.disabled,
.btn-bitbucket:disabled
{
    color: #fff;
    border-color: #fff; 
    background-color: #205081;
}
.btn-bitbucket:not(:disabled):not(.disabled):active,
.btn-bitbucket:not(:disabled):not(.disabled).active,
.show > .btn-bitbucket.dropdown-toggle
{
    color: #fff;
    border-color: #dfdfdf; 
    background-color: #163758;
}
.btn-bitbucket:not(:disabled):not(.disabled):active:focus,
.btn-bitbucket:not(:disabled):not(.disabled).active:focus,
.show > .btn-bitbucket.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}

.btn-dropbox
{
    color: #fff;
    border-color: #fff; 
    background-color: #1087dd;
    background-color: #1087dd;
}
.btn-dropbox:hover
{
    color: #fff;
    border-color: #1087dd; 
    background-color: #1087dd;
}
.btn-dropbox:focus,
.btn-dropbox.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}
.btn-dropbox.disabled,
.btn-dropbox:disabled
{
    color: #fff;
    border-color: #fff; 
    background-color: #1087dd;
}
.btn-dropbox:not(:disabled):not(.disabled):active,
.btn-dropbox:not(:disabled):not(.disabled).active,
.show > .btn-dropbox.dropdown-toggle
{
    color: #fff;
    border-color: #dfdfdf; 
    background-color: #0d6aad;
}
.btn-dropbox:not(:disabled):not(.disabled):active:focus,
.btn-dropbox:not(:disabled):not(.disabled).active:focus,
.show > .btn-dropbox.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}

.btn-facebook
{
    color: #fff;
    border-color: #fff; 
    background-color: #3b5998;
    background-color: #3b5998;
}
.btn-facebook:hover
{
    color: #fff;
    border-color: #3b5998; 
    background-color: #3b5998;
}
.btn-facebook:focus,
.btn-facebook.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}
.btn-facebook.disabled,
.btn-facebook:disabled
{
    color: #fff;
    border-color: #fff; 
    background-color: #3b5998;
}
.btn-facebook:not(:disabled):not(.disabled):active,
.btn-facebook:not(:disabled):not(.disabled).active,
.show > .btn-facebook.dropdown-toggle
{
    color: #fff;
    border-color: #dfdfdf; 
    background-color: #2d4373;
}
.btn-facebook:not(:disabled):not(.disabled):active:focus,
.btn-facebook:not(:disabled):not(.disabled).active:focus,
.show > .btn-facebook.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}

.btn-flickr
{
    color: #fff;
    border-color: #fff; 
    background-color: #ff0084;
    background-color: #ff0084;
}
.btn-flickr:hover
{
    color: #fff;
    border-color: #ff0084; 
    background-color: #ff0084;
}
.btn-flickr:focus,
.btn-flickr.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}
.btn-flickr.disabled,
.btn-flickr:disabled
{
    color: #fff;
    border-color: #fff; 
    background-color: #ff0084;
}
.btn-flickr:not(:disabled):not(.disabled):active,
.btn-flickr:not(:disabled):not(.disabled).active,
.show > .btn-flickr.dropdown-toggle
{
    color: #fff;
    border-color: #dfdfdf; 
    background-color: #cc006a;
}
.btn-flickr:not(:disabled):not(.disabled):active:focus,
.btn-flickr:not(:disabled):not(.disabled).active:focus,
.show > .btn-flickr.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}

.btn-foursquare
{
    color: #fff;
    border-color: #fff; 
    background-color: #f94877;
    background-color: #f94877;
}
.btn-foursquare:hover
{
    color: #fff;
    border-color: #f94877; 
    background-color: #f94877;
}
.btn-foursquare:focus,
.btn-foursquare.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}
.btn-foursquare.disabled,
.btn-foursquare:disabled
{
    color: #fff;
    border-color: #fff; 
    background-color: #f94877;
}
.btn-foursquare:not(:disabled):not(.disabled):active,
.btn-foursquare:not(:disabled):not(.disabled).active,
.show > .btn-foursquare.dropdown-toggle
{
    color: #fff;
    border-color: #dfdfdf; 
    background-color: #f71752;
}
.btn-foursquare:not(:disabled):not(.disabled):active:focus,
.btn-foursquare:not(:disabled):not(.disabled).active:focus,
.show > .btn-foursquare.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}

.btn-github
{
    color: #fff;
    border-color: #fff; 
    background-color: #444;
    background-color: #444;
}
.btn-github:hover
{
    color: #fff;
    border-color: #444; 
    background-color: #444;
}
.btn-github:focus,
.btn-github.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}
.btn-github.disabled,
.btn-github:disabled
{
    color: #fff;
    border-color: #fff; 
    background-color: #444;
}
.btn-github:not(:disabled):not(.disabled):active,
.btn-github:not(:disabled):not(.disabled).active,
.show > .btn-github.dropdown-toggle
{
    color: #fff;
    border-color: #dfdfdf; 
    background-color: #2b2b2b;
}
.btn-github:not(:disabled):not(.disabled):active:focus,
.btn-github:not(:disabled):not(.disabled).active:focus,
.show > .btn-github.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}

.btn-google
{
    color: #fff;
    border-color: #fff; 
    background-color: #dd4b39;
    background-color: #dd4b39;
}
.btn-google:hover
{
    color: #fff;
    border-color: #dd4b39; 
    background-color: #dd4b39;
}
.btn-google:focus,
.btn-google.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}
.btn-google.disabled,
.btn-google:disabled
{
    color: #fff;
    border-color: #fff; 
    background-color: #dd4b39;
}
.btn-google:not(:disabled):not(.disabled):active,
.btn-google:not(:disabled):not(.disabled).active,
.show > .btn-google.dropdown-toggle
{
    color: #fff;
    border-color: #dfdfdf; 
    background-color: #c23321;
}
.btn-google:not(:disabled):not(.disabled):active:focus,
.btn-google:not(:disabled):not(.disabled).active:focus,
.show > .btn-google.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}

.btn-instagram
{
    color: #fff;
    border-color: #fff; 
    background-color: #3f729b;
    background-color: #3f729b;
}
.btn-instagram:hover
{
    color: #fff;
    border-color: #3f729b; 
    background-color: #3f729b;
}
.btn-instagram:focus,
.btn-instagram.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}
.btn-instagram.disabled,
.btn-instagram:disabled
{
    color: #fff;
    border-color: #fff; 
    background-color: #3f729b;
}
.btn-instagram:not(:disabled):not(.disabled):active,
.btn-instagram:not(:disabled):not(.disabled).active,
.show > .btn-instagram.dropdown-toggle
{
    color: #fff;
    border-color: #dfdfdf; 
    background-color: #305777;
}
.btn-instagram:not(:disabled):not(.disabled):active:focus,
.btn-instagram:not(:disabled):not(.disabled).active:focus,
.show > .btn-instagram.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}

.btn-linkedin
{
    color: #fff;
    border-color: #fff; 
    background-color: #007bb6;
    background-color: #007bb6;
}
.btn-linkedin:hover
{
    color: #fff;
    border-color: #007bb6; 
    background-color: #007bb6;
}
.btn-linkedin:focus,
.btn-linkedin.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}
.btn-linkedin.disabled,
.btn-linkedin:disabled
{
    color: #fff;
    border-color: #fff; 
    background-color: #007bb6;
}
.btn-linkedin:not(:disabled):not(.disabled):active,
.btn-linkedin:not(:disabled):not(.disabled).active,
.show > .btn-linkedin.dropdown-toggle
{
    color: #fff;
    border-color: #dfdfdf; 
    background-color: #005983;
}
.btn-linkedin:not(:disabled):not(.disabled):active:focus,
.btn-linkedin:not(:disabled):not(.disabled).active:focus,
.show > .btn-linkedin.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}

.btn-microsoft
{
    color: #fff;
    border-color: #fff; 
    background-color: #2672ec;
    background-color: #2672ec;
}
.btn-microsoft:hover
{
    color: #fff;
    border-color: #2672ec; 
    background-color: #2672ec;
}
.btn-microsoft:focus,
.btn-microsoft.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}
.btn-microsoft.disabled,
.btn-microsoft:disabled
{
    color: #fff;
    border-color: #fff; 
    background-color: #2672ec;
}
.btn-microsoft:not(:disabled):not(.disabled):active,
.btn-microsoft:not(:disabled):not(.disabled).active,
.show > .btn-microsoft.dropdown-toggle
{
    color: #fff;
    border-color: #dfdfdf; 
    background-color: #125acd;
}
.btn-microsoft:not(:disabled):not(.disabled):active:focus,
.btn-microsoft:not(:disabled):not(.disabled).active:focus,
.show > .btn-microsoft.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}

.btn-odnoklassniki
{
    color: #fff;
    border-color: #fff; 
    background-color: #f4731c;
    background-color: #f4731c;
}
.btn-odnoklassniki:hover
{
    color: #fff;
    border-color: #f4731c; 
    background-color: #f4731c;
}
.btn-odnoklassniki:focus,
.btn-odnoklassniki.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}
.btn-odnoklassniki.disabled,
.btn-odnoklassniki:disabled
{
    color: #fff;
    border-color: #fff; 
    background-color: #f4731c;
}
.btn-odnoklassniki:not(:disabled):not(.disabled):active,
.btn-odnoklassniki:not(:disabled):not(.disabled).active,
.show > .btn-odnoklassniki.dropdown-toggle
{
    color: #fff;
    border-color: #dfdfdf; 
    background-color: #d35b0a;
}
.btn-odnoklassniki:not(:disabled):not(.disabled):active:focus,
.btn-odnoklassniki:not(:disabled):not(.disabled).active:focus,
.show > .btn-odnoklassniki.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}

.btn-openid
{
    color: #2a2e30;
    border-color: #fff; 
    background-color: #f7931e;
    background-color: #f7931e;
}
.btn-openid:hover
{
    color: #2a2e30;
    border-color: #f7931e; 
    background-color: #f7931e;
}
.btn-openid:focus,
.btn-openid.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(223, 224, 224, .5);
            box-shadow: 0 0 0 .2rem rgba(223, 224, 224, .5);
}
.btn-openid.disabled,
.btn-openid:disabled
{
    color: #2a2e30;
    border-color: #fff; 
    background-color: #f7931e;
}
.btn-openid:not(:disabled):not(.disabled):active,
.btn-openid:not(:disabled):not(.disabled).active,
.show > .btn-openid.dropdown-toggle
{
    color: #fff;
    border-color: #dfdfdf; 
    background-color: #da7908;
}
.btn-openid:not(:disabled):not(.disabled):active:focus,
.btn-openid:not(:disabled):not(.disabled).active:focus,
.show > .btn-openid.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(223, 224, 224, .5);
            box-shadow: 0 0 0 .2rem rgba(223, 224, 224, .5);
}

.btn-pinterest
{
    color: #fff;
    border-color: #fff; 
    background-color: #cb2027;
    background-color: #cb2027;
}
.btn-pinterest:hover
{
    color: #fff;
    border-color: #cb2027; 
    background-color: #cb2027;
}
.btn-pinterest:focus,
.btn-pinterest.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}
.btn-pinterest.disabled,
.btn-pinterest:disabled
{
    color: #fff;
    border-color: #fff; 
    background-color: #cb2027;
}
.btn-pinterest:not(:disabled):not(.disabled):active,
.btn-pinterest:not(:disabled):not(.disabled).active,
.show > .btn-pinterest.dropdown-toggle
{
    color: #fff;
    border-color: #dfdfdf; 
    background-color: #9f191f;
}
.btn-pinterest:not(:disabled):not(.disabled):active:focus,
.btn-pinterest:not(:disabled):not(.disabled).active:focus,
.show > .btn-pinterest.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}

.btn-reddit
{
    color: #2a2e30;
    border-color: #000; 
    background-color: #eff7ff;
    background-color: #eff7ff;
}
.btn-reddit:hover
{
    color: #fff;
    border-color: #000; 
    background-color: #000;
}
.btn-reddit:focus,
.btn-reddit.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(6, 7, 7, .5);
            box-shadow: 0 0 0 .2rem rgba(6, 7, 7, .5);
}
.btn-reddit.disabled,
.btn-reddit:disabled
{
    color: #2a2e30;
    border-color: #000; 
    background-color: #eff7ff;
}
.btn-reddit:not(:disabled):not(.disabled):active,
.btn-reddit:not(:disabled):not(.disabled).active,
.show > .btn-reddit.dropdown-toggle
{
    color: #2a2e30;
    border-color: black; 
    background-color: #bcdeff;
}
.btn-reddit:not(:disabled):not(.disabled):active:focus,
.btn-reddit:not(:disabled):not(.disabled).active:focus,
.show > .btn-reddit.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(6, 7, 7, .5);
            box-shadow: 0 0 0 .2rem rgba(6, 7, 7, .5);
}

.btn-soundcloud
{
    color: #fff;
    border-color: #fff; 
    background-color: #f50;
    background-color: #f50;
}
.btn-soundcloud:hover
{
    color: #fff;
    border-color: #f50; 
    background-color: #f50;
}
.btn-soundcloud:focus,
.btn-soundcloud.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}
.btn-soundcloud.disabled,
.btn-soundcloud:disabled
{
    color: #fff;
    border-color: #fff; 
    background-color: #f50;
}
.btn-soundcloud:not(:disabled):not(.disabled):active,
.btn-soundcloud:not(:disabled):not(.disabled).active,
.show > .btn-soundcloud.dropdown-toggle
{
    color: #fff;
    border-color: #dfdfdf; 
    background-color: #c40;
}
.btn-soundcloud:not(:disabled):not(.disabled):active:focus,
.btn-soundcloud:not(:disabled):not(.disabled).active:focus,
.show > .btn-soundcloud.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}

.btn-tumblr
{
    color: #fff;
    border-color: #fff; 
    background-color: #2c4762;
    background-color: #2c4762;
}
.btn-tumblr:hover
{
    color: #fff;
    border-color: #2c4762; 
    background-color: #2c4762;
}
.btn-tumblr:focus,
.btn-tumblr.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}
.btn-tumblr.disabled,
.btn-tumblr:disabled
{
    color: #fff;
    border-color: #fff; 
    background-color: #2c4762;
}
.btn-tumblr:not(:disabled):not(.disabled):active,
.btn-tumblr:not(:disabled):not(.disabled).active,
.show > .btn-tumblr.dropdown-toggle
{
    color: #fff;
    border-color: #dfdfdf; 
    background-color: #1c2e3f;
}
.btn-tumblr:not(:disabled):not(.disabled):active:focus,
.btn-tumblr:not(:disabled):not(.disabled).active:focus,
.show > .btn-tumblr.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}

.btn-twitter
{
    color: #2a2e30;
    border-color: #fff; 
    background-color: #55acee;
    background-color: #55acee;
}
.btn-twitter:hover
{
    color: #2a2e30;
    border-color: #55acee; 
    background-color: #55acee;
}
.btn-twitter:focus,
.btn-twitter.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(223, 224, 224, .5);
            box-shadow: 0 0 0 .2rem rgba(223, 224, 224, .5);
}
.btn-twitter.disabled,
.btn-twitter:disabled
{
    color: #2a2e30;
    border-color: #fff; 
    background-color: #55acee;
}
.btn-twitter:not(:disabled):not(.disabled):active,
.btn-twitter:not(:disabled):not(.disabled).active,
.show > .btn-twitter.dropdown-toggle
{
    color: #fff;
    border-color: #dfdfdf; 
    background-color: #2795e9;
}
.btn-twitter:not(:disabled):not(.disabled):active:focus,
.btn-twitter:not(:disabled):not(.disabled).active:focus,
.show > .btn-twitter.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(223, 224, 224, .5);
            box-shadow: 0 0 0 .2rem rgba(223, 224, 224, .5);
}

.btn-vimeo
{
    color: #fff;
    border-color: #fff; 
    background-color: #1ab7ea;
    background-color: #1ab7ea;
}
.btn-vimeo:hover
{
    color: #fff;
    border-color: #1ab7ea; 
    background-color: #1ab7ea;
}
.btn-vimeo:focus,
.btn-vimeo.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}
.btn-vimeo.disabled,
.btn-vimeo:disabled
{
    color: #fff;
    border-color: #fff; 
    background-color: #1ab7ea;
}
.btn-vimeo:not(:disabled):not(.disabled):active,
.btn-vimeo:not(:disabled):not(.disabled).active,
.show > .btn-vimeo.dropdown-toggle
{
    color: #fff;
    border-color: #dfdfdf; 
    background-color: #1295bf;
}
.btn-vimeo:not(:disabled):not(.disabled):active:focus,
.btn-vimeo:not(:disabled):not(.disabled).active:focus,
.show > .btn-vimeo.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}

.btn-vk
{
    color: #fff;
    border-color: #fff; 
    background-color: #587ea3;
    background-color: #587ea3;
}
.btn-vk:hover
{
    color: #fff;
    border-color: #587ea3; 
    background-color: #587ea3;
}
.btn-vk:focus,
.btn-vk.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}
.btn-vk.disabled,
.btn-vk:disabled
{
    color: #fff;
    border-color: #fff; 
    background-color: #587ea3;
}
.btn-vk:not(:disabled):not(.disabled):active,
.btn-vk:not(:disabled):not(.disabled).active,
.show > .btn-vk.dropdown-toggle
{
    color: #fff;
    border-color: #dfdfdf; 
    background-color: #466482;
}
.btn-vk:not(:disabled):not(.disabled):active:focus,
.btn-vk:not(:disabled):not(.disabled).active:focus,
.show > .btn-vk.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}

.btn-yahoo
{
    color: #fff;
    border-color: #fff; 
    background-color: #720e9e;
    background-color: #720e9e;
}
.btn-yahoo:hover
{
    color: #fff;
    border-color: #720e9e; 
    background-color: #720e9e;
}
.btn-yahoo:focus,
.btn-yahoo.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}
.btn-yahoo.disabled,
.btn-yahoo:disabled
{
    color: #fff;
    border-color: #fff; 
    background-color: #720e9e;
}
.btn-yahoo:not(:disabled):not(.disabled):active,
.btn-yahoo:not(:disabled):not(.disabled).active,
.show > .btn-yahoo.dropdown-toggle
{
    color: #fff;
    border-color: #dfdfdf; 
    background-color: #500a6f;
}
.btn-yahoo:not(:disabled):not(.disabled):active:focus,
.btn-yahoo:not(:disabled):not(.disabled).active:focus,
.show > .btn-yahoo.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 255, 255, .5);
}

.btn-outline-adn
{
    color: #d87a68; 
    border: 1px solid #d87a68 !important;
}

.btn-outline-bitbucket
{
    color: #205081; 
    border: 1px solid #205081 !important;
}

.btn-outline-dropbox
{
    color: #1087dd; 
    border: 1px solid #1087dd !important;
}

.btn-outline-facebook
{
    color: #3b5998; 
    border: 1px solid #3b5998 !important;
}

.btn-outline-flickr
{
    color: #ff0084; 
    border: 1px solid #ff0084 !important;
}

.btn-outline-foursquare
{
    color: #f94877; 
    border: 1px solid #f94877 !important;
}

.btn-outline-github
{
    color: #444; 
    border: 1px solid #444 !important;
}

.btn-outline-google
{
    color: #dd4b39; 
    border: 1px solid #dd4b39 !important;
}

.btn-outline-instagram
{
    color: #3f729b; 
    border: 1px solid #3f729b !important;
}

.btn-outline-linkedin
{
    color: #007bb6; 
    border: 1px solid #007bb6 !important;
}

.btn-outline-microsoft
{
    color: #2672ec; 
    border: 1px solid #2672ec !important;
}

.btn-outline-odnoklassniki
{
    color: #f4731c; 
    border: 1px solid #f4731c !important;
}

.btn-outline-openid
{
    color: #f7931e; 
    border: 1px solid #f7931e !important;
}

.btn-outline-pinterest
{
    color: #cb2027; 
    border: 1px solid #cb2027 !important;
}

.btn-outline-reddit
{
    color: #ff4500; 
    border: 1px solid #ff4500 !important;
}

.btn-outline-soundcloud
{
    color: #f50; 
    border: 1px solid #f50 !important;
}

.btn-outline-tumblr
{
    color: #2c4762; 
    border: 1px solid #2c4762 !important;
}

.btn-outline-twitter
{
    color: #55acee; 
    border: 1px solid #55acee !important;
}

.btn-outline-vimeo
{
    color: #1ab7ea; 
    border: 1px solid #1ab7ea !important;
}

.btn-outline-vk
{
    color: #587ea3; 
    border: 1px solid #587ea3 !important;
}

.btn-outline-yahoo
{
    color: #720e9e; 
    border: 1px solid #720e9e !important;
}

.btn-outline-adn:hover
{
    color: #ad412d;
    border: 1px solid #ad412d !important;
}

.btn-outline-bitbucket:hover
{
    color: #0c1d2f;
    border: 1px solid #0c1d2f !important;
}

.btn-outline-dropbox:hover
{
    color: #094d7e;
    border: 1px solid #094d7e !important;
}

.btn-outline-facebook:hover
{
    color: #1e2e4f;
    border: 1px solid #1e2e4f !important;
}

.btn-outline-flickr:hover
{
    color: #99004f;
    border: 1px solid #99004f !important;
}

.btn-outline-foursquare:hover
{
    color: #d4073d;
    border: 1px solid #d4073d !important;
}

.btn-outline-github:hover
{
    color: #111;
    border: 1px solid #111 !important;
}

.btn-outline-google:hover
{
    color: #96271a;
    border: 1px solid #96271a !important;
}

.btn-outline-instagram:hover
{
    color: #223d52;
    border: 1px solid #223d52 !important;
}

.btn-outline-linkedin:hover
{
    color: #003650;
    border: 1px solid #003650 !important;
}

.btn-outline-microsoft:hover
{
    color: #0e459e;
    border: 1px solid #0e459e !important;
}

.btn-outline-odnoklassniki:hover
{
    color: #a24608;
    border: 1px solid #a24608 !important;
}

.btn-outline-openid:hover
{
    color: #a95e06;
    border: 1px solid #a95e06 !important;
}

.btn-outline-pinterest:hover
{
    color: #731216;
    border: 1px solid #731216 !important;
}

.btn-outline-reddit:hover
{
    color: #992900;
    border: 1px solid #992900 !important;
}

.btn-outline-soundcloud:hover
{
    color: #930;
    border: 1px solid #930 !important;
}

.btn-outline-tumblr:hover
{
    color: #0c141c;
    border: 1px solid #0c141c !important;
}

.btn-outline-twitter:hover
{
    color: #147bc9;
    border: 1px solid #147bc9 !important;
}

.btn-outline-vimeo:hover
{
    color: #0d7091;
    border: 1px solid #0d7091 !important;
}

.btn-outline-vk:hover
{
    color: #344b61;
    border: 1px solid #344b61 !important;
}

.btn-outline-yahoo:hover
{
    color: #2e0640;
    border: 1px solid #2e0640 !important;
}

.bg-adn
{
    background-color: #d87a68;
}

.bg-bitbucket
{
    background-color: #205081;
}

.bg-dropbox
{
    background-color: #1087dd;
}

.bg-facebook
{
    background-color: #3b5998;
}

.bg-flickr
{
    background-color: #ff0084;
}

.bg-foursquare
{
    background-color: #f94877;
}

.bg-github
{
    background-color: #444;
}

.bg-google
{
    background-color: #dd4b39;
}

.bg-instagram
{
    background-color: #3f729b;
}

.bg-linkedin
{
    background-color: #007bb6;
}

.bg-microsoft
{
    background-color: #2672ec;
}

.bg-odnoklassniki
{
    background-color: #f4731c;
}

.bg-openid
{
    background-color: #f7931e;
}

.bg-pinterest
{
    background-color: #cb2027;
}

.bg-reddit
{
    background-color: #ff4500;
}

.bg-soundcloud
{
    background-color: #f50;
}

.bg-tumblr
{
    background-color: #2c4762;
}

.bg-twitter
{
    background-color: #55acee;
}

.bg-vimeo
{
    background-color: #1ab7ea;
}

.bg-vk
{
    background-color: #587ea3;
}

.bg-yahoo
{
    background-color: #720e9e;
}

/*=========================================================================================
    File Name: demo.scss
    Description: CSS used for demo purpose only. Remove this css from your project.
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax HTML Admin Template
    Version: 1.1
    Author: PIXINVENT
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
.fonticon-container > .fonticon-wrap
{
    line-height: 4.8rem;

    float: left;

    width: 60px;
    height: 60px;
    margin-right: 1rem;
    margin-bottom: 1.5rem; 

    text-align: center;

    border-radius: .1875rem;
}
.fonticon-container > .fonticon-wrap > i
{
    font-size: 2.28rem;

    -webkit-transition: all .2s ease-in-out;
       -moz-transition: all .2s ease-in-out;
         -o-transition: all .2s ease-in-out;
            transition: all .2s ease-in-out;
}
.fonticon-container > .fonticon-wrap.youtube
{
    width: 100px;
}

.fonticon-container:hover i
{
    font-size: 2.9rem;

    -webkit-transform: scale(1.3);
       -moz-transform: scale(1.3);
        -ms-transform: scale(1.3);
         -o-transform: scale(1.3);
            transform: scale(1.3); 

    color: #7367f0;
}

.fonticon-container > .fonticon-classname,
.fonticon-container > .fonticon-unit
{
    font-size: 1.5rem;
    line-height: 1.2; 

    display: block;
}

.fonticon-container > .fonticon-unit
{
    font-size: 1rem;
    font-style: italic;
}

.scroll-example
{
    position: relative;

    overflow: auto; 

    padding: .5rem;

    border: 2px solid #dfdfdf;
}
.scroll-example .horz-scroll-content
{
    width: 1200px;
}

.browser
{
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box; 
    width: 100%;
    height: 12rem;
    margin: 0 0 10px;
    padding-top: 20px;

    border: 4px solid #e0e0e0;
    background: #e0e0e0;
}

.browser iframe
{
    width: 100%; 
    height: 100%;

    border: 0;
    background: #fff;
}

.loader-wrapper
{
    height: 8em;
}

.maintenance-icon
{
    font-size: 4rem;
}

.animationIcon
{
    right: 30px;
    bottom: 10px;
}

/*=========================================================================================
	File Name: customizer.scss
	Description: CSS used for demo purpose only. Remove this css from your project.
	----------------------------------------------------------------------------------------
	Item Name: Vuesax HTML Admin Template
	Version: 1.1
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
.customizer
{
    position: fixed;
    z-index: 1051;
    top: 0;
    right: -400px;
    bottom: 0;

    width: 400px;
    height: 100vh;
    padding: 0;

    -webkit-transition: right .4s cubic-bezier(.05, .74, .2, .99);
       -moz-transition: right .4s cubic-bezier(.05, .74, .2, .99);
         -o-transition: right .4s cubic-bezier(.05, .74, .2, .99);
            transition: right .4s cubic-bezier(.05, .74, .2, .99);

    border-left: 1px solid rgba(0, 0, 0, .05);
    background-color: #fff;
    -webkit-box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
            box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08); 

    -webkit-backface-visibility: hidden;
       -moz-backface-visibility: hidden;
            backface-visibility: hidden;
}
.customizer.open
{
    right: 0;
}
.customizer .customizer-content
{
    position: relative;

    height: 100%;
}
.customizer .customizer-close
{
    position: absolute;
    z-index: 10;
    top: 20px;
    right: 30px;

    width: auto;
    padding: 7px;

    color: #626262;
}
.customizer .customizer-close i
{
    font-size: 1.71rem;
}
.customizer .customizer-toggle
{
    line-height: 40px;

    position: absolute;
    top: 50%;
    left: -39px;

    display: block;

    width: 38px;
    height: 38px;

    cursor: pointer; 
    text-align: center;

    color: #fff;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background: #7367f0;
    -webkit-box-shadow: -3px 0 8px rgba(0, 0, 0, .1);
            box-shadow: -3px 0 8px rgba(0, 0, 0, .1);
}
.customizer .color-box
{
    width: 35px;
    height: 35px;
    margin: .5rem;

    cursor: pointer; 

    border-radius: .5rem;
}
.customizer .color-box.selected
{
    -webkit-box-shadow: 0 0 0 3px rgba(52, 144, 220, .5);
            box-shadow: 0 0 0 3px rgba(52, 144, 220, .5);
}

body.dark-layout .hide-scroll-top-switch .custom-switch .custom-control-label:before,
body.dark-layout .collapse-sidebar .custom-switch .custom-control-label:before
{
    background-color: #10163a;
}

.buy-now
{
    position: fixed;
    z-index: 1031; 
    right: 79px;
    bottom: 5%;
}
.buy-now .btn
{
    -webkit-box-shadow: 0 1px 20px 1px #ea5455 !important;
            box-shadow: 0 1px 20px 1px #ea5455 !important;
}
.buy-now .btn:hover
{
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
}

.chip
{
    font-size: .8rem;

    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display:    -moz-inline-box;
    display: -ms-inline-flexbox;
    display:         inline-flex;

    margin-bottom: 5px;
    padding: 0 10px;

    vertical-align: middle;

    border-radius: 1.428rem;
    background-color: #f0f0f0;

    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
    -ms-flex-pack: center;
            justify-content: center;
}
.chip .chip-body
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    min-width: 1.857rem; 
    min-height: 1.857rem;

    color: rgba(0, 0, 0, .7);

    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
    -ms-flex-pack: justify;
            justify-content: space-between;
}
.chip .chip-body .avatar
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    width: 24px;
    height: 24px;
    margin: 2px 0;

    -webkit-transform: translate(-8px);
       -moz-transform: translate(-8px);
        -ms-transform: translate(-8px);
         -o-transform: translate(-8px);
            transform: translate(-8px); 

    color: #fff;
    border-radius: 50%;
    background-color: #c3c3c3;

    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
    -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
    -ms-flex-align: center;
            align-items: center;
}
.chip .chip-body .avatar .avatar-content
{
    top: 0;
}
.chip .chip-body .avatar img
{
    width: 24px; 
    height: 24px;

    border-radius: 50%;
}
.chip .chip-body .chip-text
{
    vertical-align: middle;

    -webkit-align-self: center;
    -ms-flex-item-align: center;
            align-self: center;
}
.chip .chip-body .chip-closeable
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    min-width: 1.428rem;
    min-height: 1.428rem;
    margin: 0 4px;

    cursor: pointer; 
    -webkit-transform: translate(10px);
       -moz-transform: translate(10px);
        -ms-transform: translate(10px);
         -o-transform: translate(10px);
            transform: translate(10px);

    color: #fff;
    border-radius: 50%;
    background: rgba(0, 0, 0, .15);

    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
    -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
    -ms-flex-align: center;
            align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
            align-self: center;
}
.chip .chip-body .chip-closeable i
{
    margin-top: 1px; 
    margin-left: 1px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
{
    .chip .chip-closeable i
    {
        position: relative;
        top: 3px;
    }
}

.divider
{
    display: block;
    overflow: hidden;

    margin: 1rem 0; 

    text-align: center;
    white-space: nowrap;
}
.divider .divider-text
{
    font-size: .9375rem;

    position: relative;

    display: inline-block;

    padding: 0 1rem;

    background-color: #fff;
}
.divider .divider-text i
{
    font-size: 1rem;
}
.divider .divider-text:before,
.divider .divider-text:after
{
    position: absolute;
    top: 50%;

    width: 9999px;

    content: '';

    border-top: 1px solid rgba(0, 0, 0, .1);
}
.divider .divider-text:before
{
    right: 100%;
}
.divider .divider-text:after
{
    left: 100%;
}
.divider.divider-left .divider-text
{
    float: left;
}
.divider.divider-left .divider-text:before
{
    display: none;
}
.divider.divider-left-center .divider-text
{
    left: -25%;
}
.divider.divider-right .divider-text
{
    float: right;
}
.divider.divider-right .divider-text:after
{
    display: none;
}
.divider.divider-right-center .divider-text
{
    right: -25%;
}
.divider.divider-dotted .divider-text:before,
.divider.divider-dotted .divider-text:after
{
    border-width: 1px;
    border-style: dotted;
    border-color: black; 
    border-top-width: 0;
}
.divider.divider-dashed .divider-text:before,
.divider.divider-dashed .divider-text:after
{
    border-width: 1px;
    border-style: dashed;
    border-color: black; 
    border-top-width: 0;
}

@media screen and (min-width: 0)
{
    head
    {
        font-family: 'xs 0px';
    }
    body:after
    {
        content: 'xs - min-width: 0px';
    }
}

@media screen and (min-width: 544px)
{
    head
    {
        font-family: 'sm 544px';
    }
    body:after
    {
        content: 'sm - min-width: 544px';
    }
}

@media screen and (min-width: 768px)
{
    head
    {
        font-family: 'md 768px';
    }
    body:after
    {
        content: 'md - min-width: 768px';
    }
}

@media screen and (min-width: 992px)
{
    head
    {
        font-family: 'lg 992px';
    }
    body:after
    {
        content: 'lg - min-width: 992px';
    }
}

@media screen and (min-width: 1200px)
{
    head
    {
        font-family: 'xl 1200px';
    }
    body:after
    {
        content: 'xl - min-width: 1200px';
    }
}

head
{
    clear: both;
}
head title
{
    font-family: 'xs 0px, sm 544px, md 768px, lg 992px, xl 1200px';
}

body:after
{
    display: none;
}

*[data-usn-if]
{
    display: none;
}

.group-area
{
    margin-bottom: 3rem;
}

.block
{
    display: block;

    width: 100%;
}

/* Input with icon */
.form-control-position
{
    line-height: 2.5rem;

    position: absolute;
    z-index: 2;
    top: 2px;
    right: 0;

    display: block;

    width: 2.5rem;
    height: 2.5rem;

    text-align: center;
}

/*---------------------------------
Input Icon
---------------------------------*/
.position-relative .form-control
{
    padding-right: -webkit-calc(1.25em + 1.4rem + 1px);
    padding-right:    -moz-calc(1.25em + 1.4rem + 1px);
    padding-right:         calc(1.25em + 1.4rem + 1px);
}
.position-relative .form-control.form-control-lg ~ .form-control-position
{
    top: 10px;
}
.position-relative .form-control.form-control-sm ~ .form-control-position
{
    top: -3px;
}

/* Input Icon left */
.has-icon-left .form-control
{
    padding-right: 2rem;
    padding-left: 3rem;
}

.has-icon-left .form-control-position
{
    right: auto;
    left: inherit;
}
.has-icon-left .form-control-position i
{
    position: relative;
    left: 5px;

    color: rgba(34, 41, 47, .4);
}

input[type='color']
{
    height: -webkit-calc(1.25em + 1.4rem + 1px);
    height:    -moz-calc(1.25em + 1.4rem + 1px);
    height:         calc(1.25em + 1.4rem + 1px);
}

input[type='date'].form-control,
input[type='time'].form-control,
input[type='datetime-local'].form-control,
input[type='month'].form-control
{
    line-height: 1.45;
}

.font-size-large
{
    font-size: 2rem;
}

.font-size-base
{
    font-size: 1rem;
}

.font-size-small
{
    font-size: 1rem;
}

.font-size-xsmall
{
    font-size: .75rem;
}

.input-group-xs .form-control,
.input-group-xs .input-group-addon,
.input-group-xs .input-group-btn > button
{
    font-size: .725rem;
    line-height: 1.677777;

    padding: .175rem .5rem;

    border-top-left-radius: .175rem; 
    border-bottom-left-radius: .175rem;
}

.input-group-xs .input-group-btn > button
{
    border-radius: .175rem;
}

.input-group-addon
{
    padding-top: .2rem;
    padding-bottom: .2rem;
}

.floating-label-form-group-with-focus label,
.floating-label-form-group-with-value label
{
    font-weight: 400; 

    text-transform: uppercase;

    color: #7367f0 !important;
}

.select2-container--classic .select2-selection--single
{
    min-height: 40px !important;
}

.select2-container
{
    width: 100% !important;
}

.select2-container--classic:focus,
.select2-container--default:focus
{
    outline: none;
}

.select2-container--classic .select2-selection--single,
.select2-container--default .select2-selection--single
{
    min-height: 38px;
    padding: 5px;

    border: 1px solid rgba(0, 0, 0, .2);
}
.select2-container--classic .select2-selection--single:focus,
.select2-container--default .select2-selection--single:focus
{
    border-color: #7367f0 !important;
    outline: 0;
    -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .15) !important;
            box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .15) !important;
}
.select2-container--classic .select2-selection--single .select2-selection__rendered i,
.select2-container--default .select2-selection--single .select2-selection__rendered i
{
    margin-right: .5rem;
}
.select2-container--classic .select2-selection--single .select2-selection__arrow,
.select2-container--default .select2-selection--single .select2-selection__arrow
{
    min-height: 38px !important;
}

.select2-container--classic.select2-container--open .select2-selection--single,
.select2-container--default.select2-container--open .select2-selection--single
{
    border-color: #7367f0 !important;
    outline: 0;
}

.select2-container--classic.select2-container--focus,
.select2-container--default.select2-container--focus
{
    outline: 0;
}
.select2-container--classic.select2-container--focus .select2-selection--multiple,
.select2-container--default.select2-container--focus .select2-selection--multiple
{
    border-color: #7367f0 !important;
    outline: 0;
}

.select2-container--classic .select2-selection--multiple,
.select2-container--default .select2-selection--multiple
{
    min-height: 38px !important;

    border: 1px solid rgba(0, 0, 0, .2);
}
.select2-container--classic .select2-selection--multiple:focus,
.select2-container--default .select2-selection--multiple:focus
{
    border-color: #7367f0 !important;
    outline: 0;
    -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .15) !important;
            box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .15) !important;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice,
.select2-container--default .select2-selection--multiple .select2-selection__choice
{
    padding: 5px; 

    color: #fff;
    border-color: #4839eb !important;
    background-color: #7367f0 !important;
}
.select2-container--classic .select2-selection--multiple .select2-selection__rendered li .select2-search__field,
.select2-container--default .select2-selection--multiple .select2-selection__rendered li .select2-search__field
{
    margin-top: 10px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove,
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove
{
    float: right;

    margin-left: .5rem; 

    color: #fff !important;
}
.select2-container--classic .select2-selection--multiple i,
.select2-container--default .select2-selection--multiple i
{
    position: relative;
    top: 1px;

    margin-right: .5rem;
    padding-left: 1px;
}
.select2-container--classic .select2-selection--multiple[class*=bg-] .select2-selection__choice,
.select2-container--default .select2-selection--multiple[class*=bg-] .select2-selection__choice
{
    border-color: rgba(0, 0, 0, .2) !important; 
    background-color: rgba(0, 0, 0, .15) !important;
}

.select2-container--classic .select2-results__options .select2-results__option i,
.select2-container--default .select2-results__options .select2-results__option i
{
    margin-right: .5rem;
}

.select2-container--classic .select-lg,
.select2-container--default .select-lg
{
    font-size: 1.2rem;

    min-height: -webkit-calc(1.25em + 1.4rem + 1px) !important;
    min-height:    -moz-calc(1.25em + 1.4rem + 1px) !important;
    min-height:         calc(1.25em + 1.4rem + 1px) !important;
    margin-bottom: 0 !important;
    padding: .3rem .7rem;
}
.select2-container--classic .select-lg.select2-selection--single .select2-selection__rendered,
.select2-container--default .select-lg.select2-selection--single .select2-selection__rendered
{
    padding-top: .1rem;
    padding-right: 0; 
    padding-left: 0;
}
.select2-container--classic .select-lg.select2-selection--single .select2-selection__arrow,
.select2-container--default .select-lg.select2-selection--single .select2-selection__arrow
{
    top: .2rem !important;
}
.select2-container--classic .select-lg.select2-selection--multiple,
.select2-container--default .select-lg.select2-selection--multiple
{
    padding: 0 .2rem;
}
.select2-container--classic .select-lg.select2-selection--multiple .select2-selection__rendered,
.select2-container--default .select-lg.select2-selection--multiple .select2-selection__rendered
{
    padding-top: 0 !important;
}
.select2-container--classic .select-lg.select2-selection--multiple .select2-selection__rendered li,
.select2-container--default .select-lg.select2-selection--multiple .select2-selection__rendered li
{
    font-size: 1.2rem;
}
.select2-container--classic .select-lg.select2-selection--multiple .select2-selection__rendered .select2-selection__choice,
.select2-container--default .select-lg.select2-selection--multiple .select2-selection__rendered .select2-selection__choice
{
    padding: 5px;
}

.select2-container--classic .select-sm,
.select2-container--default .select-sm
{
    font-size: .75rem;
    line-height: 1.5; 

    min-height: -webkit-calc(1em + 1rem + 2px) !important;
    min-height:    -moz-calc(1em + 1rem + 2px) !important;
    min-height:         calc(1em + 1rem + 2px) !important;
    margin-bottom: 0 !important;
    padding: 0 .2rem;
}
.select2-container--classic .select-sm.select2-selection--single .select2-selection__arrow,
.select2-container--default .select-sm.select2-selection--single .select2-selection__arrow
{
    top: -.3rem !important;
}
.select2-container--classic .select-sm.select2-selection--multiple,
.select2-container--default .select-sm.select2-selection--multiple
{
    line-height: 1.3;
}
.select2-container--classic .select-sm.select2-selection--multiple .select2-selection__rendered,
.select2-container--default .select-sm.select2-selection--multiple .select2-selection__rendered
{
    padding: 3px;
}
.select2-container--classic .select-sm.select2-selection--multiple .select2-selection__rendered li,
.select2-container--default .select-sm.select2-selection--multiple .select2-selection__rendered li
{
    font-size: .75rem;

    margin-top: 2px;
}
.select2-container--classic .select-sm.select2-selection--multiple .select2-selection__choice,
.select2-container--default .select-sm.select2-selection--multiple .select2-selection__choice
{
    padding: 0 .2rem;
}
.select2-container--classic .select-sm.select2-selection--multiple .select2-search--inline .select2-search__field,
.select2-container--default .select-sm.select2-selection--multiple .select2-search--inline .select2-search__field
{
    margin-top: 0;
}

.select2 .form-control::focus
{
    border-color: #7367f0 !important;
}

table.dataTable
{
    border: 2px solid #f8f8f8;
}
table.dataTable th,
table.dataTable td
{
    border-top: 0; 
    border-bottom: 1px solid #f8f8f8;
}
table.dataTable thead th,
table.dataTable thead td,
table.dataTable tfoot th,
table.dataTable tfoot td
{
    font-size: .85rem;

    border: 0;
}
table.dataTable thead tr
{
    background-color: #f8f8f8;
}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc
{
    padding-right: inherit;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before
{
    font-family: 'feather';
    font-size: .7rem;

    top: .9rem; 
    left: 0;

    padding-right: .3rem;

    content: '\e845';
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after
{
    font-family: 'feather';
    font-size: .7rem;

    top: 1.3rem; 
    left: 0;

    content: '\e842';
}
table.dataTable.table-striped tbody tr:nth-of-type(even)
{
    background-color: #f8f8f8;
}
table.dataTable.table-striped tbody tr:nth-of-type(odd)
{
    background-color: #fff;
}
table.dataTable.complex-headers
{
    border: 1px solid #dae1e7;
}
table.dataTable.complex-headers thead th,
table.dataTable.complex-headers thead td,
table.dataTable.complex-headers tfoot th,
table.dataTable.complex-headers tfoot td
{
    border-right: 1px solid #dae1e7; 
    border-bottom: 1px solid #dae1e7;
}
table.dataTable tbody tr.selected
{
    color: #7367f0 !important;
    border-radius: 5px; 
    background-color: rgba(115, 103, 240, .05) !important;
    -webkit-box-shadow: 0 0 1px 0 #7367f0 !important;
            box-shadow: 0 0 1px 0 #7367f0 !important;
}
table.dataTable tbody tr.selected td,
table.dataTable tbody tr.selected th
{
    border-bottom: 0;
}

div.dataTables_wrapper div.dataTables_filter label,
div.dataTables_wrapper div.dataTables_length label
{
    margin-top: 1rem;
}

div.dataTables_wrapper div.dataTables_filter select,
div.dataTables_wrapper div.dataTables_length select
{
    padding: 0 .8rem; 

    background-position: -webkit-calc(100% - 3px) 5px, -webkit-calc(100% - 20px) 13px, 100% 0;
    background-position:    -moz-calc(100% - 3px) 5px, -moz-calc(100% - 20px) 13px, 100% 0;
    background-position:         calc(100% - 3px) 5px, calc(100% - 20px) 13px, 100% 0;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination
{
    margin-top: 1rem;
    padding-bottom: 7px;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination li a
{
    font-size: .8rem;
}

tr.group
{
    background-color: #ededed;
}

@media only screen and (max-width: 768px)
{
    div.dataTables_wrapper div.dataTables_paginate ul.pagination
    {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
           -moz-box-pack: center;
        -ms-flex-pack: center;
                justify-content: center;
    }
}
